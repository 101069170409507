@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.App {
  display: grid;
}

*{
  padding: 0;
  margin: 0;
}

span{
  font-family: "Comic Neue", cursive;
}

a{
  text-decoration: none;
  color: black;
}

h1, h2{
  color: white;
  -webkit-text-stroke: .5px black;
  font-family: 'Anton', sans-serif;
}

button{
  font-family: 'Anton', sans-serif;
}

button:hover{
  cursor: pointer;
}

/*Header section*/

#HeaderContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  font-family: 'Comic Neue', cursive;
}

#MenuItems{
  display: flex;
  gap: 10px;
  font-family: 'Comic Neue', cursive;
}

/*Hero Section*/

#HeroContainer{
  display: flex;
  justify-self: center;
  gap: 50px;
  margin-top: 50px;
  width: 80%;
}

#HeroContainer img{
  width: 50%;
  max-width: 300px;
  align-self: center;
  border: 2px solid black;
}

#HeroText{
  display: grid;
  text-align: right;
  gap: 10px;
}

#ButtonContainer{
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-self: end;
}

#ButtonContainer button{
  background: white;
  border: 1px solid gray;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  font-size: 1em;
}

/*Hehenomics Section*/

#Hehenomics{
  display: grid;
  text-align: center;
  margin-top: 50px;
  gap: 40px;
}

#HehenomicsContainer{
  display: flex;
  justify-self: center;
  gap: 20px;
}

#HehenomicsCell{
  display: grid;
  gap: 10px;
  max-width: 300px;
}

#HehenomicsCell img{
  width: 100px;
  justify-self: center;
}
#Socials{
  display: grid;
  gap: 20px;
}
#SocialsContainer{
  display: flex;
  gap: 20px;
  justify-self: center;
}
#Disclaimer{
  justify-self: center;
  width: 80%;
}
#Footer{
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background: black;
  color: white;
  margin-top: 30px;
}

@media only screen and (max-width:420px) {
  #HeaderContainer{
    display: grid;
    justify-self: center;
    justify-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  #HeroContainer{
    display: grid;
    justify-items: center;
    margin-top: 20px;
  }
  #HeroText{
    text-align: center;
  }
  #ButtonContainer{
    display: flex;
  }
  #HehenomicsContainer{
    display: grid;
  }
  #Footer{
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }
}